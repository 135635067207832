import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchRefillList, delRefill, addRefill, fetchMemberList, updateRefill } from "@/api/member";
import { transBanKai } from "@/utils/util";
import "@/utils/global.js";
import Pagination from "@/components/Pagination";
export default {
  name: "cardList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: "",
        status: ""
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      cardDialog: false,
      dialogForm: {
        member_id: ""
      },
      memberLoading: false,
      memberOps: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchRefillList(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      console.log("添加");
      addRefill(this.dialogForm).then(function (res) {
        console.log(res); // 成功之后刷新或
        // this.dataList.unshift('返回的数据')

        _this2.$message({
          type: "success",
          message: "充值管理员添加成功"
        });

        _this2.cardDialog = false;

        _this2.getTableData();
      }).catch(function (err) {
        _this2.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleDel: function handleDel(row) {
      var _this3 = this;

      console.log("删除");
      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664 ".concat(row.name, "?");
      var title = "删除";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var data = {
          manager_id: row.id
        };
        delRefill(data).then(function (res) {
          console.log(res);

          _this3.$message({
            type: "success",
            message: "删除成功"
          });

          var index = _this3.dataList.indexOf(row);

          _this3.dataList.splice(index, 1);
        }).catch(function (err) {
          _this3.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    remoteVillage: function remoteVillage(query) {
      var _this4 = this;

      console.log("remote", query);

      if (query !== "") {
        this.memberLoading = true;
        setTimeout(function () {
          var data = {
            keyword: query
          };
          fetchMemberList(data).then(function (res) {
            console.log(res);
            _this4.memberLoading = false;
            _this4.memberOps = res.data;
          }).catch(function (err) {
            console.log(err);
            _this4.memberLoading = false;
          });
        }, 100);
      } else {
        this.memberOps = [];
      }
    },
    handleState: function handleState(row) {
      var _this5 = this;

      console.log("禁启", row);
      var status = row.status;
      var selectStatus = status == 1 ? 0 : 1;
      var arr = ["启用", "禁用"];
      var content = "\u60A8\u786E\u8BA4\u8981 ".concat(arr[row.status], "  ").concat(row.name, "?");
      var title = "禁启";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var data = {
          id: row.id,
          status: selectStatus
        };
        updateRefill(data).then(function (res) {
          console.log(res);

          _this5.$message({
            type: "success",
            message: "".concat(arr[row.status], " \u6210\u529F")
          });

          _this5.getTableData();
        }).catch(function (err) {
          _this5.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    handleAdd: function handleAdd() {
      this.cardDialog = true;
      this.dialogForm = {
        member_id: ""
      };
    },
    transBanKai: transBanKai
  }
};